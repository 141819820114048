import schemaService from "@/services/schemaService"
import searching from "@/services/searching"
import { list } from "@/services/schemaApi"
import { and } from "@/services/filtering"

//This service exists to ensure that AppSearchSelectMultiple can be copy pasted
//in future and is not overfitted the old code in this app
export async function search(entityKey, searchText, filter = null) {
    let schema = schemaService.getSchema(entityKey);
    let params = {};
    let searchFilter = searching.buildSearchFilter(schema.fields, searchText, 1);
    let filters = [searchFilter, filter].filter(f => f != null);

    params.filter = filters.length == 1 ?
        filters[0] : filters.length > 1 ?
            and(filters) : null;
    let listResult = await list(entityKey, params);
    let result = await schemaService.getIdLabels(listResult.items, schema.fields);
    return {
        items: result
    }
}

export default {
    search
};

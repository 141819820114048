import schemaService from "@/services/schemaService"

//This service exists to ensure that AppSearchSelectMultiple can be copy pasted
//in future and is not overfitted the old code in this app
export async function getEntityLabel(entityKey, item) {
    let schema = schemaService.getSchema(entityKey);
    let label = await schemaService.getLabel(item, schema.fields);
    return label;
}

export default {
    getEntityLabel
};
